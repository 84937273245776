<template>
  <svg
    width="35"
    height="40"
    viewBox="0 0 35 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 16.6667C22.1024 16.6667 25.8333 12.9357 25.8333 8.33333C25.8333 3.73096 22.1024 0 17.5 0C12.8976 0 9.16667 3.73096 9.16667 8.33333C9.16667 12.9357 12.8976 16.6667 17.5 16.6667Z"
      fill="#D3D4ED"
    />
    <path
      d="M17.5 20C7.83333 20 0 27.0833 0 35.8333C0 36.3805 0.107774 36.9223 0.317169 37.4278C0.526563 37.9334 0.833478 38.3927 1.22039 38.7796C1.6073 39.1665 2.06663 39.4734 2.57215 39.6828C3.07768 39.8922 3.61949 40 4.16667 40H30.8333C31.3805 40 31.9223 39.8922 32.4278 39.6828C32.9334 39.4734 33.3927 39.1665 33.7796 38.7796C34.1665 38.3927 34.4734 37.9334 34.6828 37.4278C34.8922 36.9223 35 36.3805 35 35.8333C35 27.0833 27.1667 20 17.5 20Z"
      fill="#D3D4ED"
    />
  </svg>
</template>

<script>
export default {
  name: 'UserSVG',
}
</script>
