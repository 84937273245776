<template>
  <v-list>
    <span v-for="(item, i) in items" :key="i">
      <v-list-item
        v-if="item.children == 0"
        link
        :to="item.router"
        active-class="active-item"
      >
        <v-list-item-icon>
          <v-icon color="white" v-text="item.icon"></v-icon>
        </v-list-item-icon>

        <v-list-item-title
          class="white--text font-weight-bold ml-n6"
          v-text="item.title"
        ></v-list-item-title>
      </v-list-item>

      <v-list-group
        v-else
        :prepend-icon="item.icon"
        :value="activeDropdown(item.title)"
        class="white--text"
      >
        <template v-slot:activator>
          <v-list-item-title
            v-text="item.title"
            class="white--text font-weight-bold ml-n6"
          ></v-list-item-title>
        </template>

        <v-list-item
          v-for="(child, id) in item.children"
          :key="id"
          link
          :to="child.router"
          active-class="active-item"
        >
          <v-list-item-icon>
            <v-icon color="white" class="ml-5" v-text="child.icon"></v-icon>
          </v-list-item-icon>

          <v-list-item-title
            class="white--text font-weight-bold ml-n3"
            v-text="child.title"
          ></v-list-item-title>
        </v-list-item>
      </v-list-group>
    </span>
  </v-list>
</template>

<script>
export default {
  name: 'NavCli',
  data: () => ({
    selectedItem: 0,
    items: [
      {
        icon: 'fa-home',
        title: 'Inicio',
        router: '/home',
        children: [],
      },
      {
        icon: 'fa-users',
        title: 'Usuarios',
        router: '',
        children: [
          {
            icon: 'fa-user-injured',
            title: 'Pacientes',
            router: '/patients',
          },
          {
            icon: 'fa-user-tie',
            title: 'Administrativos',
            router: '/admins',
          },
        ],
      },
      // {
      //   icon: 'fa-money-check-alt',
      //   title: 'Presupuestos',
      //   router: '',
      //   children: [],
      // },
      // {
      //   icon: 'fa-credit-card',
      //   title: 'Pagos',
      //   router: '',
      //   children: [],
      // },
      {
        icon: 'fa-tv',
        title: 'Dispositivos',
        router: '/',
        children: [
          {
            icon: 'fa-tv',
            title: 'Monitores',
            router: '/monitors',
          },
          {
            icon: 'fa-laptop',
            title: 'Cajas',
            router: '/cashdesks',
          },
          {
            icon: 'fa-print',
            title: 'Impresoras',
            router: '/printers',
          },
          {
            icon: 'fa-syringe',
            title: 'Estaciones',
            router: '/samplingstations',
          },
        ],
      },
      {
        icon: 'fa-book-open',
        title: 'Catálogo',
        router: '/',
        children: [
          {
            icon: 'fa-flask',
            title: 'Categorías',
            router: '/categories',
          },
          {
            icon: 'fa-vial',
            title: 'Exámenes',
            router: '/tests',
          },
          {
            icon: 'fa-list-ul',
            title: 'Indicaciones',
            router: '/indications',
          },
        ],
      },
      {
        icon: 'fa-history',
        title: 'Historial usuarios',
        router: '/userlogs',
        children: [],
      },
    ],
  }),
  computed: {
    withChildren: function () {
      return this.items.filter((i) => i.children == 0)
    },

    whitoutChildren: function () {
      return this.items.filter((i) => i.children != 0)
    },
  },
  methods: {
    activeDropdown: function (page) {
      switch (page) {
        case 'Usuarios':
          return [
            'Admin',
            'NewAdmin',
            'EditAdmin',
            'Patient',
            'EditPatient',
          ].includes(this.$route.name)
          break
        case 'Dispositivos':
          return ['Monitor', 'CashDesk', 'SamplingStation', 'Printer'].includes(
            this.$route.name
          )
          break

        default:
          return false
          break
      }
    },
  },
}
</script>
