<template>
  <v-app-bar app color="primary" dense dark>
    <v-app-bar-nav-icon
      @click="$emit('menu')"
      :class="$vuetify.breakpoint.xsOnly ? 'white--text' : 'd-none white--text'"
    >
    </v-app-bar-nav-icon>

    <v-spacer></v-spacer>

    <div class="mr-10">
      <v-icon small> fa-user </v-icon>
      <span class="ml-2 text-capitalize font-weight-bold">{{ name }}</span>
    </div>

    <v-btn @click="logout" outlined>
      <v-icon small>fa-sign-out-alt</v-icon>
      <span class="ml-2 text-capitalize hidden-sm-and-down font-weight-bold"
        >Cerrar Sesión</span
      >
    </v-btn>
  </v-app-bar>
</template>

<script>
import Swal from 'sweetalert2'
import jwt from 'jsonwebtoken'

export default {
  name: 'TopBar',
  data: () => ({}),
  computed: {
    name: function () {
      return this.$store.state.auth.user?.name || ''
    },
    verifyUser: function () {
      try {
        const user = JSON.parse(localStorage.getItem('user'))
        const decodedToken = jwt.decode(user.token)

        const now = Math.floor(Date.now() / 1000)
        return decodedToken && decodedToken.exp > now
      } catch (error) {
        console.log(error)
        return false
      }
    },
  },
  methods: {
    logout: async function () {
      try {
        await this.$store.dispatch('auth/logOut')
        location.replace('/')
      } catch (error) {
        if (error.response?.status === 401) {
          localStorage.removeItem('user')
          location.reload()
        }

        const response = error.response ? error.response.data.message : error

        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response,
        })
      }
    },
  },
  mounted() {
    if (!this.verifyUser) {
      Swal.fire({
        icon: 'warning',
        title: 'Sesión expirada',
        text: 'Por favor, vuelva a iniciar sesión',
      }).then((result) => {
        localStorage.removeItem('user')
        location.reload()
      })
    }
  },
}
</script>
