import Client from './Clients/AxiosClient'
const resource = '/ticket'

export default {
  inQueue() {
    return Client.get(`${resource}/inqueue`)
  },
  samplingInqueue() {
    return Client.get(`${resource}/samplingInqueue`)
  },
  attended() {
    return Client.get(`${resource}/attended`)
  },
  attendedSampling() {
    return Client.get(`${resource}/attendedSampling`)
  },
}
