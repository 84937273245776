<template>
  <div>
    <top-bar @menu="drawer = !drawer" />

    <v-navigation-drawer
      v-model="drawer"
      :permanent="$vuetify.breakpoint.xsOnly ? false : true"
      :app="$vuetify.breakpoint.xsOnly ? false : true"
      color="grey-cli"
      :absolute="$vuetify.breakpoint.xsOnly ? true : false"
      height="100vh"
    >
      <v-img
        :src="require('@/assets/logo.png')"
        class="my-5"
        contain
        height="100"
      />

      <nav-kuro v-if="roleUser == 1" />
      <nav-cli v-else />

      <template v-slot:append>
        <div class="text-center white--text mb-1 font-work-sans">
          <span>Última conexión: {{ formatDate(lastConnection) }}</span>
          <br />
          <span>{{ formatHours(lastConnection) }}</span>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { format } from 'date-fns'
import NavCli from './NavCli.vue'
import NavKuro from './NavKuro.vue'
import TopBar from './TopBar.vue'
export default {
  components: { TopBar, NavKuro, NavCli },
  name: 'Navegation',
  data: () => ({
    drawer: true,
    date: '01/02/2021',
    hour: '10:00 P.M.',
  }),
  computed: {
    roleUser: function () {
      return this.$store.state.auth.user?.roleId || null
    },
    lastConnection: function () {
      return null
    },
  },
  // created: function () {
  //   this.test()
  // },
  methods: {
    formatDate: (value) => {
      const date = value ? new Date(value) : new Date()
      return format(date, 'dd/MM/yyyy')
    },
    formatHours: (value) => {
      const date = value ? new Date(value) : new Date()
      return date.toLocaleString('es-VE', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      })
    },
  },
}
</script>
