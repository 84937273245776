import Client from './Clients/AxiosClient'
const resource = '/errorlogs'

export default {
  get() {
    return Client.get(`${resource}`)
  },
  //   getDeleted() {
  //     return Client.get(`${resource}?status=false`)
  //   },
  //   getUser(id) {
  //     return Client.get(`${resource}/${id}`)
  //   },
  //   create(payload) {
  //     return Client.post(`${resource}`, payload)
  //   },
  //   update(payload, id) {
  //     return Client.put(`${resource}/${id}`, payload)
  //   },
  //   delete(id) {
  //     return Client.delete(`${resource}/${id}`)
  //   },
  //   reactivate(id) {
  //     return Client.put(`${resource}/reactivate/${id}`)
  //   },
}
