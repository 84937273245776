import Client from './Clients/AxiosClient'
const resource = '/patient/admin'

export default {
  get() {
    return Client.get(`${resource}?status=true`)
  },
  getDeleted() {
    return Client.get(`${resource}?status=false`)
  },
  getPatient(id) {
    return Client.get(`${resource}/${id}`)
  },
  create(payload) {
    return Client.post(`${resource}`, payload)
  },
  update(payload, id) {
    return Client.put(`${resource}/${id}`, payload)
  },
  delete(id) {
    return Client.delete(`${resource}/${id}`)
  },
  reactivate(id) {
    return Client.patch(`${resource}/reactivate/${id}`)
  },
  restore(payload, id) {
    return Client.put(`${resource}/${id}/2FA`, payload)
  },
}
