import Client from './Clients/AxiosClient'
const resource = '/cashDesk'

export default {
  get() {
    return Client.get(`${resource}?status=true`)
  },
  getDeleted() {
    return Client.get(`${resource}?status=false`)
  },
  getAll() {
    return Client.get(`${resource}/all`)
  },
  getCashDesk(id) {
    return Client.get(`${resource}/${id}`)
  },
  create(payload) {
    return Client.post(`${resource}`, payload)
  },
  update(payload, id) {
    return Client.put(`${resource}/${id}`, payload)
  },
  delete(id) {
    return Client.put(`${resource}/status/${id}`, { status: false })
  },
  reactivate(id) {
    return Client.put(`${resource}/status/${id}`, { status: true })
  },
}
