import Client from './Clients/AxiosClient'
const resource = '/internalConfiguration'

export default {
  get() {
    return Client.get(`${resource}`)
  },
  updateToken(payload) {
    return Client.put(`${resource}/token`, payload)
  },
  updatePlaylist(payload) {
    return Client.put(`${resource}/playlist`, payload)
  },
}
