<template>
  <v-app>
    <v-main :class="token ? 'background bg-img' : 'background bg-img-login'">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <navegation v-if="token" />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Navegation from './components/Navegation.vue'
export default {
  components: {
    Navegation,
  },
  name: 'App',

  data: () => ({
    overlay: false,
    token: '',
  }),
  watch: {
    overlay(val) {
      val &&
        setTimeout(() => {
          this.overlay = false
        }, 1000)
    },
  },
  mounted: function () {
    this.$eventBus.on('loading-page', () => {
      this.overlay = true
    })
    this.token = localStorage.getItem('user')
  },
}
</script>

<style lang="sass">
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap')
@import '~vuetify/src/styles/styles.sass'

$body-font-family: 'Raleway', sans-serif !default
$heading-font-family: $body-font-family !default

.v-application
  font-family: 'Raleway', sans-serif  !important

  [class*='text-h']
    font-family: 'Raleway', sans-serif  !important
    font-weight: 500


.theme--dark.v-divider
  border-color: rgba(map-get($shades, 'white'), 1) !important

.v-divider.v-divider--vertical.theme--light, .theme--light.v-divider
  border-color: var(--v-primary-base) !important

.bg-img
  background-image: url('./assets/triangulos3.svg')
  background-size: 100%

.bg-img-login
  background-image: url('./assets/triangulos1.svg'), url('./assets/triangulos2.svg')
  background-size: 50%
  background-position: left top, right bottom
  background-repeat: no-repeat

.v-alert__icon.v-icon
    font-size: 17px !important

.absolute
  position: absolute !important
  right: 0
  top:0

.v-list-group__header__prepend-icon
    .v-icon
        color: #FEFEFE !important

.v-list-group__header__append-icon
    .v-icon
        color: #FEFEFE !important

.active-item
  background-color: #2F57A4
  border-radius: 50px 0px 0px 50px

.bg-card
  background: rgba(254, 254, 254, 0.5) !important
  border: 0.5px solid #313131 !important
  box-sizing: border-box !important

.font-work-sans, td
  font-family: 'Work Sans', sans-serif !important

.font-quicksand
  font-family: 'Quicksand', sans-serif !important
</style>
