<template>
  <svg
    width="45"
    height="45"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5 24V12C13.5 7.05 17.55 3 22.5 3C27.45 3 31.5 7.05 31.5 12V24"
      stroke="#6A83BA"
      stroke-width="4"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M33 43.5H12C10.35 43.5 9 42.15 9 40.5V24C9 22.35 10.35 21 12 21H33C34.65 21 36 22.35 36 24V40.5C36 42.15 34.65 43.5 33 43.5Z"
      fill="#F2BB41"
      stroke="#F2BB41"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.5 36C21.6 36 21 35.4 21 34.5V30C21 29.1 21.6 28.5 22.5 28.5C23.4 28.5 24 29.1 24 30V34.5C24 35.4 23.4 36 22.5 36Z"
      fill="#354C75"
      stroke="#354C75"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'LockSVG',
}
</script>
