import Vue from 'vue'
import Vuex from 'vuex'
import Repository from '@/repositories/RepositoryFactory'
const AuthRepository = Repository.get('auth')

Vue.use(Vuex)

const user = JSON.parse(localStorage.getItem('user'))

const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null }

const auth = {
  namespaced: true,
  state: initialState,

  actions: {
    login: async ({ commit }, data) => {
      try {
        const res = await AuthRepository.login(data)
        const user = res.data.data
        localStorage.setItem('user', JSON.stringify(user))

        commit('loginSuccess', user)
      } catch (error) {
        throw error
      }
    },

    logOut: async ({ commit }) => {
      try {
        await AuthRepository.logOut()
        localStorage.removeItem('user')

        commit('logout')
      } catch (error) {
        throw error
      }
    },
  },

  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true
      state.user = user
    },

    logout(state) {
      state.status.loggedIn = false
      state.user = null
    },
  },
}

const alerts = {
  namespaced: true,
  state: {
    isActive: false,
    type: 'success',
    title: '',
    text: '',
  },

  actions: {
    activeAlerts: ({ commit }, data) => {
      const { type, title, text } = data
      commit('updateIsActive', true)
      commit('updateAlerts', { type, title, text })
    },

    desactiveAlerts: ({ commit }) => {
      commit('updateIsActive', false)
    },
  },

  mutations: {
    updateIsActive: (state, value) => {
      state.isActive = value
    },
    updateAlerts: (state, value) => {
      state.type = value.type
      state.title = value.title
      state.text = value.text

      setTimeout(() => {
        state.isActive = false
      }, 5000)
    },
  },
}

export default new Vuex.Store({
  modules: {
    auth: auth,
    alerts: alerts,
  },
})
