import AuthRepository from './AuthRepository'
import UserRepository from './UserRepository'
import PatientRepository from './PatientRepository'
import MonitorRepository from './MonitorRepository'
import CashDeskRepository from './CashDeskRepository'
import SamplingStationRepository from './SamplingStationRepository'
import PrinterRepository from './PrinterRepository'
import UserLogRepository from './UserLogRepository'
import ErrorLogRepository from './ErrorLogRepository'
import CategoryRepository from './CategoryRepository'
import TestRepository from './TestRepository'
import IndicationRepository from './IndicationRepository'
import DetailRepository from './DetailRepository'
import ReferenceValueRepository from './ReferenceValueRepository'
import OrderRepository from './OrderRepository'
import BioanalystRepository from './BioanalystRepository'
import ContentRepository from './ContentRepository'
import ResultRepository from './ResultRepository'
import ResultValueRepository from './ResultValueRepository'
import PaymentMethodRepository from './PaymentMethodRepository'
import QueueRepository from './QueueRepository'
import InternalConfigurationRepository from './InternalConfigurationRepository'
import SyncHistoryRepository from './SyncHistoryRepository'

const repositories = {
  auth: AuthRepository,
  users: UserRepository,
  patients: PatientRepository,
  monitors: MonitorRepository,
  cashDesks: CashDeskRepository,
  samplingStations: SamplingStationRepository,
  printers: PrinterRepository,
  userLogs: UserLogRepository,
  errorLogs: ErrorLogRepository,
  categories: CategoryRepository,
  tests: TestRepository,
  indications: IndicationRepository,
  details: DetailRepository,
  referenceValues: ReferenceValueRepository,
  orders: OrderRepository,
  contents: ContentRepository,
  bioanalysts: BioanalystRepository,
  results: ResultRepository,
  resultValues: ResultValueRepository,
  paymentMethods: PaymentMethodRepository,
  queues: QueueRepository,
  internalConfiguration: InternalConfigurationRepository,
  syncHistories: SyncHistoryRepository,
}
export default {
  get: (name) => repositories[name],
}
