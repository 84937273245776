import Client from './Clients/AxiosClient'
const resource = '/auth'

export default {
  login(payload) {
    return Client.post(`${resource}/login`, payload)
  },
  logOut() {
    return Client.post(`${resource}/logOut`)
  },
}
