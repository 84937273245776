import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import UserSVG from '@/components/svg/User.vue'
import LockSVG from '@/components/svg/Lock.vue'

Vue.use(Vuetify)

import es from 'vuetify/lib/locale/es'

export default new Vuetify({
  icons: {
    iconfont: 'fa',
    values: {
      usuario: {
        component: UserSVG,
      },
      candado: {
        component: LockSVG,
      },
    },
  },
  theme: {
    themes: {
      light: {
        primary: '#2F57A4',
        secondary: '#CC0000',
        success: '#26A671',
        white: '#FEFEFE',
        black: '#1C1C1C',
        'grey-cli': '#313131',
        background: '#E6E8E6',
      },
      dark: {
        white: '#FEFEFE',
      },
    },
  },
  lang: {
    locales: { es },
    current: 'es',
  },
})
